import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-stretch justify-around q-mb-xl q-pt-xl" }
const _hoisted_2 = { class: "row items-center justify-center q-pt-md q-pb-xl q-px-md" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "col flex flex-center q-mb-xl q-pt-xl" }
const _hoisted_5 = { class: "column col-auto justify-center text-center text-h3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_blog_preview = _resolveComponent("blog-preview")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    class: "blog-list",
    column: "",
    position: "start",
    title: _ctx.t('global.pages.blog'),
    description: _ctx.tp('description'),
    "overflow-up": !!_ctx.list.length
  }, _createSlots({
    breadcrumbs: _withCtx(() => [
      _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
    ]),
    _: 2
  }, [
    (_ctx.list.length)
      ? {
          name: "after-preview",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (blog) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: blog.id,
                  class: "col-12 col-sm-6 col-md-4 col-xl-3 q-px-md q-pb-lg"
                }, [
                  _createVNode(_component_blog_preview, { blog: blog }, null, 8, ["blog"])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_pagination, {
                  "model-value": _ctx.pageNumber,
                  "to-fn": _ctx.toPage,
                  max: _ctx.pagesCount,
                  size: "20px",
                  padding: "sm 0",
                  "text-color": "black",
                  "active-text-color": "white",
                  "active-design": "unelevated",
                  flat: "",
                  push: "",
                  rounded: "",
                  unelevated: ""
                }, null, 8, ["model-value", "to-fn", "max"])
              ])
            ])
          ]),
          key: "0"
        }
      : {
          name: "default",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.tp('noBlog')), 1)
            ])
          ]),
          key: "1"
        }
  ]), 1032, ["title", "description", "overflow-up"]))
}