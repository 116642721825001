import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = {
  key: 2,
  class: "absolute-bottom text-center company-card__info-label--container"
}
const _hoisted_4 = { class: "q-py-sm company-card__info-label" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "col-auto full-width" }
const _hoisted_8 = { class: "row items-center" }
const _hoisted_9 = {
  key: 0,
  class: "text-h3 col-12 text-weight-bold text-ellipsis"
}
const _hoisted_10 = {
  key: 1,
  class: "text-large col-12 text-weight-bold text-ellipsis"
}
const _hoisted_11 = {
  key: 2,
  width: "28",
  height: "28",
  alt: "Company VIP",
  src: "/images/other/star-vip.svg",
  loading: "lazy"
}
const _hoisted_12 = { class: "row text-grey-6" }
const _hoisted_13 = {
  key: 1,
  class: "col"
}
const _hoisted_14 = {
  class: "row items-center",
  style: {"margin-top":"-4px"}
}
const _hoisted_15 = { class: "col-auto text-ellipsis" }
const _hoisted_16 = { class: "text-small" }
const _hoisted_17 = ["alt", "src"]
const _hoisted_18 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_carousel_slide = _resolveComponent("q-carousel-slide")!
  const _component_q_carousel = _resolveComponent("q-carousel")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_icon_label = _resolveComponent("icon-label")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_visibility_template = _resolveComponent("visibility-template")!
  const _component_q_responsive = _resolveComponent("q-responsive")!

  return (_openBlock(), _createBlock(_component_q_responsive, {
    class: _normalizeClass([{
      'company-card__non-active': _ctx.nonActive,
      'no-pointer-events': _ctx.noPointerEvents,
      'company-card__dense': _ctx.dense,
      'company-card__occupied': _ctx.occupiedCompletely
    }, "company-card"]),
    i18n: _ctx.$options.name,
    ratio: _ctx.dense ? 21 / 8 : (_ctx.largeHeight ? 1 : 4 / 3)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_visibility_template, {
        "container-class": _ctx.containerClass,
        eager: _ctx.eager,
        onHidden: _ctx.hidden,
        onVisible: _ctx.visible
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: !_ctx.noRouting ? _ctx.to : '',
            class: "text-decoration-none",
            tag: "div",
            rel: "nofollow",
            onClick: _ctx.choose
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass([{'row': _ctx.dense, 'column': !_ctx.dense}, "fit relative-position"])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass({'col full-width': !_ctx.dense, 'col-auto full-height': _ctx.dense})
                }, [
                  (!_ctx.$slots.preview)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([{'q-py-md q-pl-md company-card__img-container--dense': _ctx.dense}, "relative-position fit company-card__img-container"])
                      }, [
                        (!_ctx.showAllImages || _ctx.dense)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              alt: _ctx.publicName,
                              class: _normalizeClass({'shadow-10': _ctx.dense && _ctx.nonActive, 'shadow-15': _ctx.dense && !_ctx.nonActive, }),
                              src: _ctx.photoSrc,
                              loading: "lazy"
                            }, null, 10, _hoisted_1))
                          : (_openBlock(), _createBlock(_component_q_carousel, {
                              key: 1,
                              modelValue: _ctx.carouselModel,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.carouselModel) = $event)),
                              arrows: _ctx.photos.length > 1,
                              animated: "",
                              infinite: "",
                              swipeable: "",
                              class: "fit",
                              "control-type": "unelevated",
                              "control-text-color": "grey-8"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photos, (p, i) => {
                                  return (_openBlock(), _createBlock(_component_q_carousel_slide, {
                                    key: i,
                                    name: i,
                                    class: "q-pa-none overflow-hidden"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("img", {
                                        alt: `${ _ctx.publicName }_#${ i }`,
                                        src: p,
                                        class: _normalizeClass({'company-card__occupied': _ctx.occupiedCompletely}),
                                        loading: "lazy"
                                      }, null, 10, _hoisted_2)
                                    ]),
                                    _: 2
                                  }, 1032, ["name"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue", "arrows"])),
                        (_ctx.imageDescription)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.imageDescription), 1)
                            ]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              (!_ctx.noPriceLabel && ((_ctx.isDefault(_ctx.company) && _ctx.defaultPriceLabel) || _ctx.occupiedCompletely))
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass([{'q-py-md q-pl-md': _ctx.dense}, "absolute-bottom text-center company-card__info-label--container"])
                                  }, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass([{'company-card__info-label--dense text-small': _ctx.dense}, "q-py-sm company-card__info-label"])
                                    }, [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("div", null, [
                                          (_ctx.isDefault(_ctx.company))
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                innerHTML: _ctx.defaultPriceLabel
                                              }, null, 8, _hoisted_5))
                                            : (_ctx.occupiedCompletely)
                                              ? (_openBlock(), _createElementBlock("div", {
                                                  key: 1,
                                                  innerHTML: _ctx.tp('unavailable')
                                                }, null, 8, _hoisted_6))
                                              : _createCommentVNode("", true)
                                        ])
                                      ])
                                    ], 2)
                                  ], 2))
                                : _createCommentVNode("", true)
                            ], 64))
                      ], 2))
                    : _renderSlot(_ctx.$slots, "preview", { key: 1 })
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass({'col-auto full-width': !_ctx.dense, 'col': _ctx.dense || _ctx.noImage})
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass([{'q-pa-md': _ctx.dense, 'q-py-sm q-px-md': !_ctx.dense}, "column justify-between full-height relative-position"])
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        (_ctx.useH1)
                          ? (_openBlock(), _createElementBlock("h1", _hoisted_9, _toDisplayString(_ctx.publicName), 1))
                          : (_openBlock(), _createElementBlock("h2", _hoisted_10, _toDisplayString(_ctx.publicName), 1)),
                        false
                          ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        (_ctx.description === undefined)
                          ? (_openBlock(), _createBlock(_component_q_skeleton, {
                              key: 0,
                              square: "",
                              height: "10px",
                              width: "156px"
                            }))
                          : (_ctx.description)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.description), 1)
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                      ])
                    ]),
                    (!_ctx.titleOnly)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["q-pb-xs col-auto full-width", {'q-pt-md q-pb-sm': !_ctx.dense}])
                          }, [
                            (_ctx.isDefault(_ctx.company))
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (_ctx.showDestination)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        (_ctx.destinationLabel)
                                          ? (_openBlock(), _createBlock(_component_icon_label, {
                                              key: 0,
                                              label: _ctx.destinationLabel,
                                              color: "primary",
                                              icon: "send",
                                              size: "sm"
                                            }, null, 8, ["label"]))
                                          : (_openBlock(), _createBlock(_component_q_skeleton, {
                                              key: 1,
                                              square: "",
                                              width: "156px"
                                            }))
                                      ], 64))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        (_ctx.ratingLabel)
                                          ? (_openBlock(), _createBlock(_component_icon_label, {
                                              key: 0,
                                              label: _ctx.ratingLabel,
                                              icon: "star",
                                              size: "sm"
                                            }, null, 8, ["label"]))
                                          : (_openBlock(), _createBlock(_component_q_skeleton, {
                                              key: 1,
                                              square: "",
                                              width: "156px"
                                            }))
                                      ], 64))
                                ], 64))
                              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.company.additions, (a, i) => {
                                  return (_openBlock(), _createElementBlock("img", {
                                    key: a,
                                    alt: _ctx.additionToIcons[a],
                                    src: `/images/other/${ _ctx.additionToIcons[a] }.svg`,
                                    class: "q-pr-xs",
                                    height: "20",
                                    width: "20"
                                  }, null, 8, _hoisted_17))
                                }), 128))
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-auto full-width", {'q-pt-xs q-pb-sm': !_ctx.dense}])
                          }, [
                            (_ctx.isDefault(_ctx.company))
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (_ctx.capacityLabel)
                                    ? (_openBlock(), _createBlock(_component_icon_label, {
                                        key: 0,
                                        label: _ctx.capacityLabel,
                                        icon: "storage",
                                        size: "sm"
                                      }, null, 8, ["label"]))
                                    : (_openBlock(), _createBlock(_component_q_skeleton, {
                                        key: 1,
                                        square: "",
                                        width: "156px"
                                      }))
                                ], 64))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  innerHTML: _ctx.selfPriceLabel
                                }, null, 8, _hoisted_18))
                          ], 2)
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ], 2)
            ]),
            _: 3
          }, 8, ["to", "onClick"])
        ]),
        _: 3
      }, 8, ["container-class", "eager", "onHidden", "onVisible"])
    ]),
    _: 3
  }, 8, ["class", "i18n", "ratio"]))
}