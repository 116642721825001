import { PreFetchOptions } from '@quasar/app-webpack'
import useCityState from 'stores/cityHome'
import useUserPreview from 'stores/userPreview'
import useAppData from 'stores/appData'
import { isPath, pathToKey } from 'src/functions/utils'
import useUserDetails from 'stores/userDetails'
import useUserLocation, { LocationType } from 'stores/userLocation'
import { ServiceTypeAlias, ServiceTypeAliases } from 'src/model/common.model'
import { INDEX, INDEX_ALIAS, INDEX_LUGGAGE, INDEX_SELF } from 'pages/names'

export default async function cityPrefetch(options: PreFetchOptions<any>, serviceTypeAlias?: ServiceTypeAlias) {
  const detailsState = useUserDetails(options.store)
  const previewState = useUserPreview(options.store)
  const cityState = useCityState(options.store)
  const appDataState = useAppData(options.store)
  const locationState = useUserLocation(options.store)

  const path = options.currentRoute.params.serviceTypeAlias as string
  const cityPath = options.currentRoute.params.city as string
  const city = pathToKey(cityPath)
  const key = pathToKey(path) as ServiceTypeAlias
  serviceTypeAlias = serviceTypeAlias || key

  const redirect301 = (name: string) => {
    options.redirect({ name }, 301)
  }

  const getRoute = (alias: ServiceTypeAlias) => {
    switch (alias) {
      case 'LUGGAGE':
        return INDEX_LUGGAGE
      case 'SELF':
        return INDEX_SELF
      default:
        return INDEX_ALIAS
    }
  }

  if (!ServiceTypeAliases.includes(serviceTypeAlias)) {
    redirect301(INDEX)
    return
  }

  if (
    ['LUGGAGE', 'SELF'].includes(key) ||
    !(path ? isPath(path) : true) ||
    !isPath(cityPath)
  ) {
    redirect301(getRoute(serviceTypeAlias))
    return
  }

  await Promise.all([
    previewState.loadAllByCity(city),
    cityState.loadAll(city, serviceTypeAlias),
    appDataState.loadReviews(detailsState.language),
    appDataState.loadBagsCount(),
  ])

  if (!previewState.city || !previewState.city.services.includes(serviceTypeAlias)) {
    console.warn(`City ${ city } does not have a service ${ serviceTypeAlias }`, previewState.city)
    redirect301(getRoute(serviceTypeAlias))
    return
  }
  locationState.setLocation({ point: previewState.city.center }, LocationType.GEO)
}
