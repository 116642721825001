
  import { defineAsyncComponent, defineComponent } from 'vue'
  import useRoutes from 'src/compositions/routes'
  import LuggageButton from 'src/components/advanced/luggage-button.vue'
  import LanguageSelector from 'src/components/advanced/language-selector.vue'
  import AuthorizationButton from 'src/components/advanced/authorization-button.vue'
  import IconImage from 'src/components/simple/icon-image.vue'
  import { useI18n$ } from 'boot/i18n'
  import useDrawer from 'src/compositions/drawer'
  import { QDrawer, QItem, QItemLabel, QItemSection, QNoSsr, QSeparator } from 'quasar'
  import useUserDetails from 'stores/userDetails'
  import { storeToRefs } from 'pinia'
  import PageLinks from 'components/simple/page/page-links.vue'
  import LocationSelect from 'components/advanced/location-select.vue'

  const name = 'page-drawer'

  const anonymousPages = [
    'blog',
    'reviews',
    'contact',
    'perks'
  ]

export default defineComponent({
  name,
  components: {
    QNoSsr,
    QDrawer,
    QItem,
    QItemSection,
    QItemLabel,
    QSeparator,
    LuggageButton,
    LanguageSelector,
    AuthorizationButton,
    IconImage,
    PageLinks,
    LocationSelect,
    AdminBtn: defineAsyncComponent(() => import(/* webpackChunkName: "admin-btn", webpackMode: "lazy" */'components/simple/page/admin-btn.vue')),
  },
  props: {},
  setup() {
    const { isPartner, isAdmin, isSecondaryAdmin } = storeToRefs(useUserDetails())
    const { toPage, toRoot } = useRoutes()
    const { tp } = useI18n$('global.pages')
    const { opened, toggle } = useDrawer()

    return {
      isPartner,
      isAdmin,
      isSecondaryAdmin,
      anonymousPages,
      opened,
      tp,
      toRoot,
      toPage,
      toggle
    }
  }
})
