import { Company } from 'src/model/company.model'
import { UpdatePayoutSettingsRequest } from 'src/api/dto/partner'
import {
  BetweenDatesCompaniesRequest, ByIdsCompanyRequest,
  CreateCompanyRequest,
  TotalOrderStatisticResponse,
  UpdateCompanyRequest, YearCompaniesRequest
} from 'src/api/dto/company'
import { PartnerOrderProjection } from 'src/model/order.model'
import { Partner } from 'src/model/partner.model'
import { BasePartnerProjection, Page, Pageable, ServiceType } from 'src/model/common.model'
import { InjectionKey } from 'vue'
import { getActivePinia, Pinia } from 'pinia'
import { injectSafe } from 'src/compositions/common'
import { PartnerMetrics } from 'src/model/partner-metrics.model'

const partnerApiKey: InjectionKey<ReturnType<typeof usePartnerApi_>> = Symbol('qeepl_partnerApi')

function usePartnerApi_(store: Pinia) {
  const { self } = store.api

  async function getCurrent(): Promise<BasePartnerProjection> {
    return self.get('/partner/current')
      .then(res => res.data)
  }

  async function getCurrentFull(): Promise<Partner> {
    return self.get('/partner-owner/current')
      .then(res => res.data)
  }

  async function getCompanies(): Promise<Company[]> {
    return self.get('/partner/companies')
      .then(res => res.data)
  }

  async function createCompany(request: CreateCompanyRequest): Promise<Company> {
    return self.post('/partner-owner/company', request)
      .then(res => res.data)
  }

  async function updateCompany(request: UpdateCompanyRequest): Promise<Company> {
    return self.put('/partner-owner/company', request)
      .then(res => res.data)
  }

  async function getLuggageDayOrderStatistic(request: BetweenDatesCompaniesRequest): Promise<TotalOrderStatisticResponse> {
    return self.post('/partner-owner/total-statistics?type=DEFAULT', request)
      .then(res => res.data)
  }

  async function getSelfDayOrderStatistic(request: YearCompaniesRequest): Promise<TotalOrderStatisticResponse> {
    return self.post('/partner-owner/total-statistics?type=SELF', request)
      .then(res => res.data)
  }

  async function getCompanyLuggageOrders(request: BetweenDatesCompaniesRequest, pageable?: Pageable): Promise<Page<PartnerOrderProjection>> {
    const pageableQuery = Pageable.map(pageable)
    return self.post(`/partner/orders${ pageableQuery }${ pageableQuery ? '&' : '?' }type=DEFAULT`, request)
      .then(res => Page.of(res.data, pageable))
  }

  async function getCompanySelfOrders(request: ByIdsCompanyRequest, pageable?: Pageable): Promise<Page<PartnerOrderProjection>> {
    const pageableQuery = Pageable.map(pageable)
    return self.post(`/partner/orders${ pageableQuery }${ pageableQuery ? '&' : '?' }type=SELF`, request)
      .then(res => Page.of(res.data, pageable))
  }

  async function markAccepted(id: string): Promise<void> {
    return self.get(`/partner/orders/${ id }/mark-accepted`)
  }

  async function markReturned(id: string): Promise<void> {
    return self.get(`/partner/orders/${ id }/mark-returned`)
  }

  async function approveOrder(id: string): Promise<void> {
    return self.get(`/partner/orders/${ id }/approve`)
  }

  async function updatePhotos(id: string, photos: string[]): Promise<PartnerOrderProjection> {
    return self.post(`/partner/orders/${ id }/update-photos`, { photos })
      .then(res => res.data)
  }

  async function getPartnerMetrics(partnerId: string): Promise<PartnerMetrics> {
    return self.get(`/partner/${ partnerId }/metrics`)
      .then(res => res.data)
  }

  async function updatePayoutSettings(request: UpdatePayoutSettingsRequest): Promise<Partner> {
    return self.post('/partner-owner/update-payout-settings', request)
      .then(res => res.data)
  }

  async function updateLegalName(legalName: string): Promise<Partner> {
    return self.post('/partner-owner/update-legal-name', { legalName })
      .then(res => res.data)
  }

  async function startOnboarding(type: string): Promise<string> {
    return self.get(`/partner-owner/stripe/start/${ type }`)
      .then(res => res.data)
  }

  async function continueOnboarding(): Promise<string> {
    return self.get('/partner-owner/stripe/continue')
      .then(res => res.data)
  }

  async function changeDetails(): Promise<string> {
    return self.get('/partner-owner/stripe/change')
      .then(res => res.data)
  }

  async function connectExistingAccount(): Promise<string> {
    return self.get('/partner-owner/stripe/connect')
      .then(res => res.data)
  }

  async function handleReturnKey(key: string): Promise<Partner> {
    return self.post('/partner-owner/stripe/handle', { key })
      .then(res => res.data)
  }

  async function handleExistingReturnKey(code: string, key: string): Promise<Partner> {
    return self.post('/partner-owner/stripe/handle-existing', { key, code })
      .then(res => res.data)
  }

  return {
    getCurrent,
    getCurrentFull,
    getCompanies,
    updateLegalName,
    updatePayoutSettings,
    createCompany,
    updateCompany,
    getLuggageDayOrderStatistic,
    getSelfDayOrderStatistic,
    getCompanyLuggageOrders,
    getCompanySelfOrders,
    startOnboarding,
    continueOnboarding,
    connectExistingAccount,
    changeDetails,
    getPartnerMetrics,
    handleReturnKey,
    handleExistingReturnKey,
    markAccepted,
    markReturned,
    approveOrder,
    updatePhotos,
  }
}

export default function usePartnerApi() {
  return injectSafe(partnerApiKey, () => usePartnerApi_(getActivePinia()!))
}
