
import { matContentCopy } from '@quasar/extras/material-icons'
import LargeBtn from 'components/simple/large-btn.vue'
import useNotify from 'src/compositions/notify'
import { defineComponent, PropType } from 'vue'
import { useI18n$ } from 'boot/i18n'
import { QBtn, QCard, QCardSection, QIcon, QImg, copyToClipboard } from 'quasar'

const name = 'perk-card'

export default defineComponent({
  name,
  components: {
    LargeBtn,
    QCard,
    QCardSection,
    QIcon,
    QBtn,
    QImg
  },
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: Function as PropType<() => string>,
      required: true
    },
    logoPath: {
      type: String,
      required: true
    },
    imagePath: {
      type: String,
      required: true
    },
    discount: {
      type: String,
      required: true
    },
    validUntil: {
      type: String,
      required: true
    },
    promoCode: {
      type: String,
      required: true
    },
    offerLink: {
      type: String,
      required: true
    }
  },
  setup() {
    const { positive } = useNotify()

    const { t } = useI18n$(name)

    const doCopy = async (value: string) => {
      await copyToClipboard(value)
      positive(t('global.operation.copied'))
    }

    return {
      matContentCopy,
      t,
      doCopy
    }
  }
})
