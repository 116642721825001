import { PreFetchOptions } from '@quasar/app-webpack'
import useBlogListHome from 'stores/blogListHome'
import useUserDetails from 'stores/userDetails'
import useAppData from 'stores/appData'

export default async function blogListPrefetch(options: PreFetchOptions<any>) {
  const { language } = useUserDetails(options.store)
  const { load: loadBlogList } = useBlogListHome(options.store)
  const { loadReviews } = useAppData(options.store)
  const pageNumber = Math.abs(+(options.currentRoute.query?.page || 0))

  await Promise.all([
    loadBlogList(language, pageNumber),
    loadReviews(language),
  ])
}
