import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    "display-value": _ctx.label,
    "model-value": _ctx.language,
    options: _ctx.options,
    borderless: "",
    class: "language-selector",
    dense: "",
    "emit-value": "",
    "map-options": "",
    onFilter: _ctx.filterOptions,
    "onUpdate:modelValue": _ctx.setLanguage
  }, {
    option: _withCtx(({ itemProps, opt }) => [
      _createVNode(_component_q_item, _mergeProps(itemProps, {
        to: _ctx.toPage(opt.value)
      }), {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(opt.label), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1040, ["to"])
    ]),
    _: 1
  }, 8, ["display-value", "model-value", "options", "onFilter", "onUpdate:modelValue"]))
}