
import { computed, defineComponent, PropType, watch } from 'vue'
import { QBtn } from 'quasar'
import ObjectCard from 'components/simple/object-card.vue'
import HomePageTemplate from 'components/template/home-page-template.vue'
import { refStore } from 'stores/__common'
import useUserLocation from 'stores/userLocation'
import useUserPreview from 'stores/userPreview'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'
import { Country } from 'src/model/country.model'
import { formatAmount } from 'src/functions/formatting'
import { aliasToServiceType, createAmount, keyToPath } from 'src/functions/utils'
import {
  CITY_FALLBACK_IMG,
  DEFAULT_CURRENCY,
  DEFAULT_INSURANCE,
  DEFAULT_LOGO_512,
  DEFAULT_SMALL_DAY_PRICE,
  DEFAULT_SMALL_MONTH_PRICE
} from 'src/model/constants'
import usePageExtensions from 'src/compositions/pageExtensions'
import useAppData from 'stores/appData'
import { BreadcrumbEntry, Container, ServiceTypeAlias } from 'src/model/common.model'
import { CityProjection } from 'src/model/city.model'
import HomeContentPageTemplate from 'components/template/home-content-page-template.vue'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'

const name = 'index-template'

export default defineComponent({
  name,
  components: {
    QBtn,
    HomeContentPageTemplate,
    HomePageTemplate,
    Breadcrumbs,
    ObjectCard,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    faq: {
      type: String,
      required: false
    },
    alias: {
      type: String as PropType<ServiceTypeAlias | 'ALL'>,
      default: 'ALL'
    },
  },
  setup(props) {
    const { location } = refStore(useUserLocation())
    const { loadAllNearest } = refStore(useUserPreview())
    const { total: totalReview } = refStore(useAppData())
    const {
      st,
      t,
      tp
    } = useI18n$(props.name)
    const {
      toCityLuggage,
      toCitySelf,
      toCityAlias,
      toMap
    } = useRoutes()
    const {
      countries,
      country,
      main5Cities,
      countryCities,
      companies,
      city,
    } = useUserPreviewExtension()

    const type = props.alias === 'ALL' ? 'ALL' : aliasToServiceType(props.alias as ServiceTypeAlias)
    const faq = props.faq || type.toLowerCase()
    const path = props.alias === 'LUGGAGE' || props.alias === 'SELF' ? `${ props.alias.toLowerCase() }-storage` : keyToPath(props.alias)

    const params = computed(() => {
      const countryValue = country.value as Country | null
      return {
        dayPrice: formatAmount(createAmount(countryValue?.smallItemPrices.dayPrice ?? DEFAULT_SMALL_DAY_PRICE, countryValue?.currency ?? DEFAULT_CURRENCY)),
        monthPrice: formatAmount(createAmount(countryValue?.smallItemPrices.monthPrice ?? DEFAULT_SMALL_MONTH_PRICE, countryValue?.currency ?? DEFAULT_CURRENCY)),
        insuranceText: formatAmount(createAmount(countryValue?.insurance ?? DEFAULT_INSURANCE, countryValue?.currency ?? DEFAULT_CURRENCY), 0),
        phrase1: '',
        phrase2: '',
      }
    })
    const filteredCountryCities = computed(() => {
      let alias: ServiceTypeAlias
      switch (props.alias) {
        case 'ALL':
        case 'LUGGAGE':
          alias = 'LUGGAGE'
          break
        case 'SELF':
          alias = 'SELF'
          break
        default:
          alias = props.alias
      }
      const result: Container<CityProjection[]> = {}
      const countryCitiesValue = countryCities.value
      Object.keys(countryCitiesValue).forEach(key => {
        if (countryCitiesValue[key].length) {
          result[key] = countryCitiesValue[key].filter(c => c.services.includes(alias))
        }
      })
      return result
    })
    const toCity = computed(() => {
      switch (props.alias) {
        case 'SELF':
          return toCitySelf
        case 'LUGGAGE':
        case 'ALL':
          return toCityLuggage
        default:
          return (city: CityProjection | string) => {
            return toCityAlias(props.alias as ServiceTypeAlias, city)
          }
      }
    })

    watch(location, newLocation => loadAllNearest(newLocation))

    return {
      type,
      countries,
      main5Cities,
      countryCities: filteredCountryCities,
      companies,
      params,
      st,
      t,
      tp,
      toCity,
      toMap,
      ...usePageExtensions({
        page: props.name,
        product: {
          priceValue: city.value?.smallItemPrices?.dayPrice ?? country.value?.smallItemPrices?.dayPrice ?? DEFAULT_SMALL_DAY_PRICE,
          currency: country.value?.currency ?? DEFAULT_CURRENCY,
          totalReview: totalReview.value
        },
        organization: {
          totalReview: totalReview.value,
          image: DEFAULT_LOGO_512
        },
        breadcrumbs: (title, description, params) => {
          return [{
            path: path as string,
            name: t(`page-footer.aliases.${ props.alias }`),
            label: t(`page-footer.aliases.${ props.alias }`)
          }]
        },
        faq: faq,
        image: DEFAULT_LOGO_512,
        params,
        preloadImages: main5Cities.value.map(c => c.photoURL || CITY_FALLBACK_IMG)
      })
    }
  }
})
