import useAnonymousApi from 'src/api/anonymous.api'
import { defineStore } from 'pinia'
import { Content } from 'src/model/content.model'
import { Page, Pageable } from 'src/model/common.model'
import { ref, computed } from 'vue'
import { Language } from 'src/model/language.model'

const createPageable = (currentPage: Page<Content>, pageSize: number, pageNumber: number): Pageable => {
  return {
    page: pageNumber,
    size: pageSize,
    sortBy: 'createdDate',
    descending: true
  }
}

const useBlogListHome = defineStore('blogListHome', () => {
  const api = useAnonymousApi()

  const pageSize = 50
  const page = ref<Page<Content>>({ data: [], totalCount: 0, page: 0 })
  const loading = ref<boolean>(false)

  const pagesCount = computed(() => {
    return Math.floor(page.value.totalCount / pageSize) || 0
  })

  const load = async (language: Language, pageNumber: number) => {
    loading.value = true
    return api.getBlogList(language, createPageable(page.value, pageSize, pageNumber))
      .then((pageResult) => {
        page.value = {...pageResult, page: pageNumber}
      })
      .finally(() => loading.value = false)
  }

  return {
    pageSize,
    pagesCount,
    page,
    loading,
    load
  }
})

export default useBlogListHome
