import { InjectionKey, onMounted, ref, watch } from 'vue'
import { LocalStorage } from 'quasar'
import { inject } from 'src/compositions/common'
import { ChargeType, DateRange, DateTime, ItemsMetadata, Range, TimeRange } from 'src/model/common.model'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import useAnonymousApi from 'src/api/anonymous.api'
import useAsync from 'src/compositions/async'
import useUserPreview from 'stores/userPreview'
import { refStore } from 'stores/__common'
import { useRouter } from 'vue-router'
import useCompaniesMap from 'stores/companiesMap'
import { removeValue, saveValue } from 'src/functions/bridge'
import { DISCOUNT_STORE_NAME, ORDER_STORE_NAME } from 'src/model/constants'
import {
  checkDateTime,
  checkDateTimeForCompany,
  convertToChargeType,
  initDefaultDateRange,
  proposeDateTimeRange,
  proposeDefaultDateTimeRange
} from 'src/compositions/user/userOrderCommon'
import {
  GetTotalPriceDefaultOrderRequest,
  GetTotalPriceRequest,
  GetTotalPriceSelfOrderRequest
} from 'src/api/dto/order'
import { OrderItemType, OrderLength } from 'src/model/order.model'
import { doWhen, isMonthly, isSelfServiceType } from 'src/functions/utils'
import { addMonths, parseDate } from 'src/functions/date'
import {
  CHARGE_TYPE_TO_ORDER_ITEM,
  computeItemsCount,
  convertToDomain,
  DEFAULT_ITEMS_METADATA,
  getActualItemsMetadata,
  getItemsMetadataOrDefault
} from 'src/functions/order'
import { getHolidays } from 'src/functions/company'

interface SavedState {
  dateRange: DateRange
  timeRange: TimeRange
  itemsMetadata: ItemsMetadata
  orderLength: OrderLength
  chargeType: ChargeType
  hourly: boolean
  discount: string | null
}

const userOrderKey: InjectionKey<ReturnType<typeof useUserOrder_>> = Symbol('qeepl_userOrder')

function useUserOrder_() {
  const { getTotalPrice } = useAnonymousApi()
  const { openCompany: company } = refStore(useCompaniesMap())
  const { city, country, companies } = refStore(useUserPreview())
  const { currentRoute } = useRouter()

  const { run: loadTotalPrice, result: totalPrice, running: loadingTotalPrice } = useAsync(
    () => {
      const request = isSelfServiceType(company.value!.type) ?
        buildGetTotalPriceSelfOrderRequest() : buildGetTotalPriceDefaultOrderRequest()
      return getTotalPrice(request, company.value!.type)
    }
  )
  const {
    run: loadTotalPriceIgnoreDiscount,
    result: totalPriceIgnoreDiscount,
    running: loadingTotalPriceIgnoreDiscount,
    clear: clearTotalPriceIgnoreDiscount
  } = useAsync(() => {
      const request = isSelfServiceType(company.value!.type) ?
        buildGetTotalPriceSelfOrderRequest(true) : buildGetTotalPriceDefaultOrderRequest(true)
      return getTotalPrice(request, company.value!.type)
    }
  )

  const dateRange = ref<DateRange>(null as unknown as DateRange)
  const timeRange = ref<TimeRange>(null as unknown as TimeRange)
  const filterEnabled = ref<boolean>(false)
  const itemsMetadata = ref<ItemsMetadata>(DEFAULT_ITEMS_METADATA)
  const hourly = ref<boolean>(false)
  const discount = ref<string | null>(null)
  const orderLength = ref<OrderLength>('SEVERAL_MONTHS')
  const chargeType = ref<ChargeType>('DAILY')
  const savedState = ref<SavedState | null>(LocalStorage.getItem(ORDER_STORE_NAME) as SavedState | null)

  onMounted(() => {
    loadCurrentState()
    setupDiscount()
  })

  const updateDateTimeRange = (newCompany: AnonymousCompanyProjection | null) => {
    let dateTime: Range<DateTime>
    if (newCompany) {
      const publicHolidays = country.value?.holidays
      if (filterEnabled.value && checkDateTimeForCompany(newCompany, dateRange.value, timeRange.value, publicHolidays)) {
        return
      }
      dateTime = proposeDateTimeRange(newCompany, dateRange.value, timeRange.value, publicHolidays)
    } else {
      if (filterEnabled.value && checkDateTime(dateRange.value, timeRange.value, city.value?.timezone)) {
        return
      }
      dateTime = proposeDefaultDateTimeRange(dateRange.value, timeRange.value, city.value?.timezone)
    }
    dateRange.value = { from: dateTime.from.date, to: dateTime.to.date }
    timeRange.value = { from: dateTime.from.time, to: dateTime.to.time }
  }
  const updateItemsCount = (newCompany: AnonymousCompanyProjection | null) => {
    doUpdateItemsCount(newCompany)
    saveCurrentState()
  }
  const doUpdateItemsCount = (newCompany: AnonymousCompanyProjection | null) => {
    if (newCompany && newCompany.default) {
      const default_ = newCompany.default!
      if (computeItemsCount(itemsMetadata.value) > (default_.capacity ?? 1)) {
        itemsMetadata.value = DEFAULT_ITEMS_METADATA
      }
    }
  }
  const updateTotalPrice = (newCompany: AnonymousCompanyProjection | null) => {
    clearTotalPriceIgnoreDiscount()
    if (newCompany) {
      loadTotalPrice()
      loadTotalPriceIgnoreDiscount()
    }
  }
  const setCompany = (newCompany: AnonymousCompanyProjection | null) => {
    doSetCompany(newCompany)
    saveCurrentState()
  }
  const doSetCompany = (newCompany: AnonymousCompanyProjection | null) => {
    updateChargeType()
    updateDateTimeRange(newCompany)
    doUpdateItemsCount(newCompany)
    updateTotalPrice(newCompany)
  }
  const setDateTime = (newDateRange: DateRange, newTimeRange: TimeRange | null) => {
    doSetDateTime(newDateRange, newTimeRange)
    saveCurrentState()
  }
  const doSetDateTime = (newDateRange: DateRange, newTimeRange: TimeRange | null) => {
    filterEnabled.value = true
    initDefaultDateTime(newDateRange, newTimeRange)
    updateChargeType()
    doUpdateItemsCount(company.value)
    updateTotalPrice(company.value)
  }
  const initDefaultDateTime = (newDateRange: DateRange, newTimeRange: TimeRange | null) => {
    const { from, to } = proposeDefaultDateTimeRange(newDateRange, newTimeRange, city.value?.timezone)
    dateRange.value = { from: from.date, to: to.date }
    timeRange.value = newTimeRange || { from: from.time, to: to.time }
  }
  const setItemsMetadata = (newIitemsMetadata: ItemsMetadata) => {
    itemsMetadata.value = getItemsMetadataOrDefault(newIitemsMetadata)
    updateItemsCount(company.value)
    updateTotalPrice(company.value)
  }
  const setOrderLength = (newOrderLength: OrderLength) => {
    const oldOrderLength = orderLength.value
    orderLength.value = newOrderLength
    if ([newOrderLength, oldOrderLength].includes('ONE_MONTH')) {
      updateTotalPrice(company.value)
    }
    saveCurrentState()
  }
  const setDiscount = (newDiscount: string | null) => {
    discount.value = newDiscount
    updateTotalPrice(company.value)
  }
  const setChargeType = (newChargeType: ChargeType) => {
    doSetChargeType(newChargeType)
    saveCurrentState()
  }
  const doSetChargeType = (newChargeType: ChargeType) => {
    if (newChargeType !== chargeType.value) {
      chargeType.value = newChargeType
      const result = convertToChargeType(newChargeType, dateRange.value, timeRange.value, companies.value)
      updateItemsMetadata(newChargeType)
      doSetDateTime(result.dateRange, result.timeRange)
    }
  }
  const updateItemsMetadata = (chargeType: ChargeType) => {
    const newItems = CHARGE_TYPE_TO_ORDER_ITEM[chargeType]
    const newItemsMetadata = getActualItemsMetadata(itemsMetadata.value)
    Object.keys(newItemsMetadata).forEach(k => {
      if (!newItems.includes(k as OrderItemType)) {
        delete newItemsMetadata[k as OrderItemType]
      }
    })
    itemsMetadata.value = getItemsMetadataOrDefault(newItemsMetadata)
  }
  const setHourly = (newHourly: boolean) => {
    if (newHourly) {
      doSetChargeType('HOURLY')
    } else {
      doSetChargeType('DAILY')
    }
  }
  const clearFilter = () => {
    filterEnabled.value = false
    hourly.value = false
  }
  const clearDiscount = () => {
    setDiscount(null)
    removeValue(DISCOUNT_STORE_NAME)
    saveCurrentState()
  }
  const setAllLuggage = (
    newDateRange: DateRange,
    newTimeRange: TimeRange,
    newItemsCount: ItemsMetadata,
    newDiscount: string | null,
    newHourly: boolean = false,
  ) => {
    if (
      (company.value && checkDateTimeForCompany(company.value!, newDateRange, newTimeRange, getHolidays(company.value!, country.value?.holidays)))
      || checkDateTime(newDateRange, newTimeRange, city.value?.timezone)
    ) {
      dateRange.value = newDateRange
      timeRange.value = newTimeRange
    }
    itemsMetadata.value = newItemsCount
    discount.value = newDiscount
    hourly.value = newHourly
    updateChargeType()
    updateDateTimeRange(company.value)
    updateTotalPrice(company.value)
    saveCurrentState()
  }
  const setAllSelf = (newDateRange: DateRange, newTimeRange: TimeRange, newOrderLength: OrderLength) => {
    if (
      (company.value && checkDateTimeForCompany(company.value!, newDateRange, newTimeRange, getHolidays(company.value!, country.value?.holidays)))
      || checkDateTime(newDateRange, newTimeRange, city.value?.timezone)
    ) {
      dateRange.value = newDateRange
      timeRange.value = newTimeRange
    }
    orderLength.value = newOrderLength
    updateChargeType()
    updateDateTimeRange(company.value)
    updateTotalPrice(company.value)
    saveCurrentState()
  }
  const updateChargeType = () => {
    let result: ChargeType = 'DAILY'
    if (hourly.value) {
      result = 'HOURLY'
    }
    const dateRangeValue = dateRange.value!
    if (addMonths(dateRangeValue.to, -1).getTime() - parseDate(dateRangeValue.from).getTime() >= 0) {
      result = 'MONTHLY'
    }
    doSetChargeType(result)
  }
  const refreshDateTimeRange = () => {
    updateDateTimeRange(company.value)
  }
  const buildGetTotalPriceDefaultOrderRequest = (ignoreDiscount?: boolean): GetTotalPriceDefaultOrderRequest => {
    return {
      ...buildGetTotalPriceRequest(),
      ...convertToDomain(itemsMetadata.value),
      discount: discount.value && !ignoreDiscount ? discount.value : undefined,
      hourly: hourly.value ? true : undefined,
      defaultDiscount: ignoreDiscount ? undefined : true,
    }
  }
  const buildGetTotalPriceSelfOrderRequest = (ignoreDiscount?: boolean): GetTotalPriceSelfOrderRequest => {
    return {
      ...buildGetTotalPriceRequest(),
      orderLength: orderLength.value,
      discount: !ignoreDiscount ? true : undefined,
    }
  }
  const buildGetTotalPriceRequest = (): GetTotalPriceRequest => {
    return {
      from: {
        date: dateRange.value.from,
        time: timeRange.value!.from
      },
      to: {
        date: dateRange.value.to,
        time: timeRange.value!.to
      },
      companyId: company.value!.id!,
    }
  }
  const setupDiscount = async () => {
    const discountValue = currentRoute.value.query.discount as string
    if (discountValue) {
      discount.value = discountValue
      await saveValue(DISCOUNT_STORE_NAME, discountValue)
    }
  }

  const saveCurrentState = () => {
    const value: SavedState = {
      dateRange: dateRange.value,
      timeRange: timeRange.value,
      itemsMetadata: itemsMetadata.value,
      discount: discount.value,
      orderLength: orderLength.value,
      hourly: hourly.value,
      chargeType: chargeType.value
    }
    LocalStorage.set(ORDER_STORE_NAME, value)
  }

  const loadCurrentState = () => {
    const savedStateValue = savedState.value
    if (savedStateValue) {
      itemsMetadata.value = savedStateValue.itemsMetadata
      discount.value = savedStateValue.discount
      orderLength.value = savedStateValue.orderLength
      hourly.value = savedStateValue.hourly
      chargeType.value = savedStateValue.chargeType
      if (checkDateTime(savedStateValue.dateRange, savedStateValue.timeRange, city.value?.timezone)) {
        dateRange.value = savedStateValue.dateRange
      } else {
        initDefaultDateTime(savedStateValue.dateRange, savedStateValue.timeRange)
      }
      LocalStorage.set(ORDER_STORE_NAME, savedStateValue)
    }
  }

  watch(() => city.value?.timezone, () => setCompany(company.value))

  initDefaultDateTime(initDefaultDateRange(city.value?.timezone), null)
  doSetCompany(company.value)

  return {
    company,
    dateRange,
    timeRange,
    itemsMetadata,
    discount,
    totalPrice,
    totalPriceIgnoreDiscount,
    hourly,
    orderLength,
    filterEnabled,
    loadingTotalPrice,
    loadingTotalPriceIgnoreDiscount,
    chargeType,
    setCompany,
    setDateTime,
    setItemsMetadata,
    setDiscount,
    clearFilter,
    clearDiscount,
    setHourly,
    setChargeType,
    setOrderLength,
    setAllLuggage,
    setAllSelf,
    refreshDateTimeRange,
  }
}

export default function useUserOrder() {
  return inject(userOrderKey, () => useUserOrder_())
}
